import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { Legal } from 'ui/screens';

const LegalPage = ({ data, location }) => {
  return <Legal data={data} location={location}></Legal>;
};

LegalPage.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object,
};

LegalPage.defaultProps = {};

export default LegalPage;

export const LegalPageQuery = graphql`
  query LegalSectionQuery {
    allMdx(filter: { frontmatter: { category: { eq: "legal" } } }) {
      nodes {
        body
        frontmatter {
          title
          author
          date
          slug
          category
          language
          keywords
        }
      }
    }
  }
`;
